import Map from 'ol/Map.js';
import View from 'ol/View.js';
import BingMaps from 'ol/source/BingMaps.js';
import OSMSource from 'ol/source/OSM.js';
import { VectorLayer } from './RendererLayer.js';
import { Tile as TileLayer } from 'ol/layer.js';
import * as olExtent from 'ol/extent';
import { Vector as VectorSource } from 'ol/source.js';
import { EditFrameTool } from './EditFrameTool.js';
import { AddFrameInteraction } from './AddFrameInteraction.js';
import { FrameLayer } from './FrameLayer.js';
import { GeoJSON } from 'ol/format';
import { Modify } from 'ol/interaction';
import { ElementsLayer } from './ElementsLayer.js';
import DrawElement from './DrawElement.js';
import { ElementFeature } from './ElementFeature.js';
import { getElement } from '@Utils/Elements.js';
import { GoogleMapTile, GoogleMapTileLayer } from './GoogleMapTile.js';
import { Control } from 'ol/control.js';
import { Translate } from 'ol/interaction.js';
import { GeometryUtils, Utils } from '@Utils/Utils.js';
import { noModifierKeys } from 'ol/events/condition.js';
import UndoRedo from 'ol-ext/interaction/UndoRedo.js';
import getIconUrl from './Cursor.js';
import { fromLonLat } from 'ol/proj';
import { toLonLat } from 'ol/proj.js';
import { deconstructHoleId } from '@Utils/DataController.js';
import { MeasureTool } from './MeasureTool.js';
import { nanoid } from 'nanoid';
import { UnionTool } from './UnionTool.js';
import { AzureMapsTile, AzureMapsTileLayer } from './AzureMaps.js'

let logos = {
	google: "/logotypes/googlemaplogo.png",
	bing: "/logotypes/bingmaplogo.png",
	azuremaps: "/logotypes/azuremapslogo.svg"
}

function applyFilters(context, properties) {
	let filter = [];
	if (properties.saturate) {
		filter.push(`saturate(${properties.saturate}%)`);
	}
	if (properties.sepia) {
		filter.push(`sepia(${properties.sepia}%)`);
	}
	if (properties.hue) {
		filter.push(`hue-rotate(${properties.hue}deg)`);
	}
	if (properties.brightness) {
		filter.push(`brightness(${properties.brightness}%)`);
	}
	if (properties.contrast) {
		filter.push(`contrast(${properties.contrast}%)`);
	}
	if (properties.grayscale) {
		filter.push(`grayscale(${properties.grayscale}%)`);
	}
	if (properties.invert) {
		filter.push(`invert(${properties.invert}%)`);
	}
	if (properties.blur) {
		filter.push(`blur(${properties.blur}px)`);
	}
	if (filter.length < 1) {
		context.filter = 'none';
		return;
	}
	context.filter = filter.join(' ');
	context.globalCompositeOperation = 'source-over';
}

export default class EditorCanvas {
	map;
	view;
	data;
	availableMapServices = [];
	target;
	bingMap;
	hasUpdates = false;
	translateTool;
	editFrameTool;
	addFrameTool = new AddFrameInteraction(import.meta.env.VITE_RENDER_HEIGHT / import.meta.env.VITE_RENDER_WIDTH);
	drawTool;
	modifyTool;
	viewPadding = [140, 100, 70, 100];
	selectedFeature;
	selectedElement;
	filterProperties = {
		google: {},
		bing: {},
		osm: {
			grayscale: 80,
			invert: 100
		}
	};
	focusedFrameFeature;
	focusedFrameLayer;
	focusedFrameSource;

	unionTool = null
	modifyFrameSource = new VectorSource();
	modifyToolSource = new VectorSource();
	sharedElementsSource = new VectorSource();
	frameElementSources = [];
	framesSource = new VectorSource();

	addFramesSource = new VectorSource();
	addFramesLayer;
	framesLayer;
	sharedElementsLayer;
	measureTool;
	history;
	listeners = {};
	currentTool;
	currentMapService = 'google';

	constructor(target, options, onRenderComplete) {
		let _this = this;
		this.target = target;
		this.undoRedo = new UndoRedo();
		this.undoRedo.setMaxLength(50);
		if (!options) {
			options = {};
		}
		this.showIntersectingElements = options.showIntersectingElements === true ?? false;
		this.showOverlappingElements = options.showOverlappingElements === true ?? false;
		document.addEventListener(
			'keydown',
			function (event) {
				if (this.modifyTool) {
					if (event.altKey) {
						this.modifyTool.pixelTolerance_ = 14;
					} else {
						this.modifyTool.pixelTolerance_ = 7;
					}
				}
				if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() == 'z') {
					if (this.currentTool) {
						return;
					}
					if (event.shiftKey) {
						this.undoRedo.redo();
					} else {
						this.undoRedo.undo();
					}
				} else if (event.key == 'Backspace' || event.key == 'Delete') {
					this.deleteSelected();
				}
			}.bind(this),
			false
		);
		let mapServices = [];
		if (options.serviceKeys) {
			if (options.serviceKeys.google) {
				this.googleMap = new GoogleMapTileLayer({
					visible: false,
					maxZoom: 22,
					source: new GoogleMapTile({
						apiKey: options.serviceKeys.google,
						mapType: 'satellite'
					})
				});
				mapServices.push(this.googleMap);
				this.availableMapServices.push('google');
				this.addMapFilters(this.googleMap, 'google');
			}
			if (options.serviceKeys.azureMaps) {
				this.azureMap = new AzureMapsTileLayer({
					visible: false,
					preload: Infinity,
					maxZoom: 23,
					source: new AzureMapsTile({
						key: options.serviceKeys.azureMaps,
						maxZoom: 23
					})
				});
				mapServices.push(this.azureMap);
				this.availableMapServices.push('azuremaps');
				this.addMapFilters(this.azureMap, 'azuremaps');
			}
			if (options.serviceKeys.bing) {
				this.bingMap = new TileLayer({
					visible: false,
					preload: Infinity,
					maxZoom: 20,
					source: new BingMaps({
						key: options.serviceKeys.bing,
						imagerySet: 'Aerial',
						maxZoom: 20
					})
				});
				mapServices.push(this.bingMap);
				this.availableMapServices.push('bing');
				this.addMapFilters(this.bingMap, 'bing');
			}
		}
		if (localStorage) {
			let service = localStorage.getItem('GolfMap_selectedMapService');
			if (this.availableMapServices.includes(service)) {
				this.currentMapService = service;
			}
		}
		// this.osmMap = new TileLayer({
		//     source: new OSMSource(),
		//     visible: false
		// });
		// mapServices.push(this.osmMap)
		// this.addMapFilters(this.osmMap, "osm")

		this.view = new View({
			//center: center,
			zoom: 16,
			maxZoom: 21
		});
		this.map = new Map({
			layers: mapServices,
			target: target,
			view: this.view
		});
		this.framesLayer = new FrameLayer(this.map, this.framesSource);
		this.addFramesLayer = new FrameLayer(this.map, this.addFramesSource);
		this.translateTool = new Translate({
			condition: function (event) {
				let features = event.map.getFeaturesAtPixel(event.pixel);
				if (features.length < 1) {
					return false;
				}
				let f = features[0];
				if (f.get('elementName') || f.getGeometry().getType() != 'Point') {
					return true;
				}
				return false; //platformModifierKeyOnly(event);
			},
			filter: function (feature, layer) {
				if (this.focusedFrameFeature == feature) {
					return this.selectedFeature == feature;
				}
				if (this.selectedFeature == feature) {
					return true;
				}
				return false;
			}.bind(this)
		});

		this.map.on(
			'click',
			function (evt) {

				if (noModifierKeys(evt) == false) {
					return;
				}
				if (this.currentTool && this.currentTool !== 'union') {
					return;
				}
				if (this.measureTool) {
					return;
				}
				let pixel = evt.pixel;
				let features = this.map.getFeaturesAtPixel(pixel);
				if (features.length < 1) {
					this.deselectFeature();
					return;
				}
				for (let feature of features) {
					if (feature.get('hidden')) {
						continue;
					}
					if (this.focusedFrameFeature && feature.get('objectType') == 'frame') {
						this.deselectFeature();
						continue;
					}
					if (feature.get('selected')) {
						continue;
					}
					if (this.unionTool) {

						this.unionTool.select(feature)
						break;
					}
					this.selectFeature(feature);
					break;
				}
			}.bind(this)
		);
		this.addFrameTool.frameAdded = function (p, f) {
			this.deactivateTool();
			setTimeout(() => {
				this.selectFeature(f);
			}, 120);
		}.bind(this);
		this.map.on('loadend', function () {
			if (onRenderComplete) {
				onRenderComplete();
			}
		});

		if (options.extent) {
			this.fit(options.extent);
		} else {
			this.fitAllFrames();
		}
		if (options.data) {
			this.loadJson(options.data);
		}
		this.map.addInteraction(this.translateTool);
		this.framesLayer.set('name', 'Frames');
		this.sharedElementsLayer = new ElementsLayer(this.map, this.sharedElementsSource, false);
		this.sharedElementsLayer.set('name', 'SharedElements');
		this.sharedElementsLayer.setZIndex(4);
		this.map.addLayer(this.sharedElementsLayer);
		this.modifyTool = new Modify({
			source: this.modifyToolSource,
			pixelTolerance: 7
		});
		this.modifyTool.on('modifyend', function (evt) {
			let f = evt.features.getArray()[0];
			//if (f.get('elementName')) {
			_this.trigger('featureUpdated', f, evt);
			//}
		});
		this.translateTool.on('translateend', function (evt) {
			let f = evt.features.getArray()[0];
			if (evt.coordinate[0] !== evt.startCoordinate[0] || evt.coordinate[1] !== evt.startCoordinate[1]) {
				_this.trigger('featureUpdated', f, evt);
				_this.updateFeatureHighlights()
			}
		});
		this.map.addInteraction(this.modifyTool);
		this.map.addLayer(this.framesLayer);
		this.framesLayer.setZIndex(1);
		this.editFrameTool = new EditFrameTool(this.map, this.modifyFrameSource, this.vector);

		this.addSourceListerners(this.sharedElementsSource);
		this.addSourceListerners(this.framesSource);
		this.setMapService(this.currentMapService, true);

		this.map.addInteraction(this.undoRedo);
	}
	escape() {
		if (this.unionTool) {
			this.unionTool.cancel()
			this.unionTool = null
			this.deactivateTool();
		} else if (this.measureTool) {
			this.deactivateTool();
		} else if (this.drawTool) {
			if (this.drawTool.getPointerCount() > 0) {
				this.drawTool.abortDrawing();
				if (this.drawTool._modifyFeature) {
					this.drawTool._modifyFeatureSource.addFeature(this.drawTool._modifyFeature)
				}
			} else {
				if (this.drawTool._modifyFeature) {
					this.drawTool._modifyFeatureSource.addFeature(this.drawTool._modifyFeature)
				}
				this.deactivateTool();
			}
		} else if (this.addFrameTool.feature_) {
			this.addFrameTool.cancel();
			this.deactivateTool();
		} else if (this.currentTool) {
			this.deactivateTool();
		} else {
			this.deselectFeature();
		}
	}
	on(event, cb) {
		if (!this.listeners[event]) {
			this.listeners[event] = [];
		}
		this.listeners[event].push(cb);
	}
	setMapFilters(properties, service) {
		if (service == 'google' || service == 'osm' || service == 'bing') {
			this.filterProperties[service] = properties;
		}
	}
	addMapFilters(tileLayer, service) {
		let _this = this;
		tileLayer.on('prerender', (evt) => {
			if (evt.context && _this.filterProperties[service]) {
				const context = evt.context;
				applyFilters(context, _this.filterProperties[service]);
			}
		});
		tileLayer.on('postrender', (evt) => {
			if (evt.context) {
				const context = evt.context;
				context.filter = 'none';
			}
		});
	}
	addMapLogo(service) {
		if (this.mapLogoControl) {
			this.map.removeControl(this.mapLogoControl);
		}
		let url = logos[service];
		if (url) {
			let c = document.createElement('img');
			c.src = url
			c.style.position = 'absolute';
			c.style.bottom = '10px';
			c.style.left = '10px';
			c.style.height = '24px';
			const mapLogoControl = new Control({ element: c });
			this.map.addControl(mapLogoControl);
			this.mapLogoControl = mapLogoControl;
		}
	}
	setMapService(service, force) {
		if (!this.availableMapServices.includes(service)) {
			return;
		}
		if (!force && this.currentMapService == service) {
			return;
		}
		localStorage.setItem('GolfMap_selectedMapService', service);
		this.currentMapService = service;
		let currentServiceLayer
		if (this.googleMap) {
			this.googleMap.setVisible(service == 'google');
			if (service === 'google') {
				this.view.setMaxZoom(21);
			}
		}
		if (this.azureMap) {
			this.azureMap.setVisible(service == 'azuremaps');
			if (service === 'azuremaps') {
				this.view.setMaxZoom(22);
			}
		}
		if (this.bingMap) {
			this.bingMap.setVisible(service == 'bing');
			if (service === 'bing') {
				this.view.setMaxZoom(19);
			}
		}
		if (this.osmMap) {
			this.osmMap.setVisible(service == 'osm');
		}
		this.trigger('mapServiceChanged', service);
		this.addMapLogo(this.currentMapService);
	}
	convertFeaturesToGeoJSON(feature) {
		let writer = new GeoJSON();
		return writer.writeFeatures(feature);
	}
	convertFeatureToGeoJSON(feature) {
		let writer = new GeoJSON();
		return writer.writeFeature(feature);
	}
	getRenderData(holeId) {
		let frame = this.getFrameFeature(holeId);
		if (!frame) {
			console.error(`Cannot find frame with id ${holeId}`);
			return null;
		}
		let writer = new GeoJSON();
		let obj = {};
		obj.id = holeId;
		obj.frame = writer.writeFeature(frame);
		obj.surroundings = writer.writeFeatures(this.sharedElementsSource.getFeatures());
		let elements = this.frameElementSources.find((s) => s.get('holeId') == holeId)
		if (elements) {
			obj.elements = writer.writeFeatures(elements.getFeatures());
		}
		obj.extent = frame.getGeometry().getExtent();
		return obj;
	}
	deleteSelected(force) {
		if (!this.selectedFeature) {
			return;
		}
		this.deleteFeature(this.selectedFeature, force);
		this.deselectFeature(this.selectedFeature);
	}
	deleteFeature(feature, force) {
		let source;
		let element = getElement(feature.get('elementName'));
		let holeId = feature.get('holeId');
		if (feature.get('objectType') == 'frame') {
			if (!force) {
				console.warn('Cannot delete frame using backspace, use force = true to delete frame');
				return;
			}
			source = this.framesSource;
			if (feature.get('focused')) {
				this.unfocus();
				this.fitAllFrames();
			}
			this.frameElementSources = this.frameElementSources.filter((s) => s.get('holeId') != holeId);
		} else if (element && element.frameOnly == true && holeId && feature.get('objectType') != 'frame') {
			source = this.frameElementSources.find((s) => s.get('holeId') == holeId);
		} else {
			source = this.sharedElementsSource;
		}
		if (source) {
			source.removeFeature(feature);
		}
		this.modifyToolSource.clear();
		this.modifyFrameSource.clear();
	}
	off(event, cb) {
		if (!this.listeners[event]) {
			return;
		}
		this.listeners[event] = this.listeners[event].filter((c) => c != cb);
	}
	fit(extent) {
		this.view.setRotation(0);
		this.view.fit(extent, {
			padding: this.viewPadding
		});
	}
	rotate(angle, animated) {
		if (animated == undefined) {
			animated = true;
		}
		if (!angle) {
			angle = Utils.degreesToRadians(0);
		}
		if (animated) {
			this.view.animate({
				duration: 500,
				rotation: angle
			});
		} else {
			this.view.setRotation(Utils.degreesToRadians(angle));
		}
	}
	panToFeature(feature) {
		this.view.fit(feature.getGeometry(), {
			padding: this.viewPadding
		});
	}
	fitFeature(feature, animated, rotate) {
		if (animated == undefined) {
			animated = true;
		}
		let angle;
		let geometry = feature.getGeometry();
		let coordinates = geometry.getCoordinates()[0];
		if (rotate && coordinates.length > 1) {
			angle = Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]) + Utils.degreesToRadians(-90);
		}
		let map = this.map;
		let view = this.view;
		if (animated) {
			let extent = geometry.getExtent();
			let center = olExtent.getCenter(extent);
			let finalZoom = map.getView().getZoomForResolution(map.getView().getResolutionForExtent(extent, map.getSize()));
			if (finalZoom < 21) {
				finalZoom = 21;
			}
			view.animate({
				center: center,
				duration: 500,
				zoom: finalZoom,
				rotation: angle,
				padding: this.viewPadding
			});
		} else {
			if (angle) {
				view.setRotation(angle);
			}
			view.fit(geometry, {
				padding: this.viewPadding
			});
		}
	}
	fitSource(source, animated) {
		if (animated === undefined) {
			animated = false;
		}
		if (source.getFeatures().length < 1) {
			return;
		}
		let extent = source.getExtent();
		if (!extent) {
			return;
		}
		if (animated) {
			let center = olExtent.getCenter(extent);
			let finalZoom = this.map.getView().getZoomForResolution(this.map.getView().getResolutionForExtent(extent, this.map.getSize()));
			this.view.animate({
				center: center,
				duration: 500,
				zoom: finalZoom,
				rotation: 0,
				padding: this.viewPadding
			});
		} else {
			this.view.setRotation(0);
			this.view.fit(extent, {
				padding: this.viewPadding
			});
		}
	}
	fitAllFrames(animated) {
		if (this.framesSource.getFeatures().length < 1) {
			this.panTo([15.908203125000002, 62.79493487887009], 5, animated);
		} else {
			this.fitSource(this.framesSource, animated);
		}
	}
	getExtent() {
		return this.view.calculateExtent(this.map.getSize());
	}
	selectFeature(feature, force) {
		this.deselectFeature();
		feature.set('clearCache', true)
		this.modifyToolSource.clear();
		this.modifyFrameSource.clear();
		if (feature == null) {
			return;
		}
		if (this.focusedFrameFeature && force !== true) {
			if (this.focusedFrameFeature == feature) {
				return;
			}
		}
		if (feature.get('elementName') && !feature.get('hidden')) {
			this.modifyToolSource.addFeatures([feature]);
		}

		this.selectedFeature = feature;
		this.selectedFeature.set('selected', true);
		if (feature.get('elementName')) {
			this.selectedElement = new ElementFeature(feature);
			this.trigger('elementSelected', this.selectedElement);
		} else if (feature.get('objectType') === 'frame') {
			this.trigger('frameSelected', feature);
			this.modifyFrameSource.addFeature(feature);
		}
		this.addFeatureListerners(this.selectedFeature);
		this.updateFeatureHighlights()
	}
	deselectFeature() {
		this.modifyToolSource.clear();
		let s = this.selectedFeature;
		let e = this.selectedElement;
		if (s) {

			this.removeFeatureListerners(s);
			this.selectedFeature.unset('selected');
			this.selectedFeature = null;
			this.selectedElement = null;
			this.trigger('elementSelected', null, e);
			if (s.get('objectType') == 'frame') {
				if (!this.focusedFrameFeature) {
					this.trigger('frameSelected', null);
				}
			}
		}
		this.updateFeatureHighlights()
	}
	getAvilableEvents() {
		return ['elementSelected', 'frameSelected', 'frameFocused', 'sourceUpdated', 'featureUpdated', 'featureRemoved', 'featureAdded', 'drawEnd', 'elementOrderChanged', 'currentToolChange', 'measurementUpdated'];
	}
	getFrame(holeId) {
		let source = this.framesSource;
		let features = source.getFeatures();
		for (let f of features) {
			if (f.get('holeId') == holeId) {
				let writer = new GeoJSON();
				return writer.writeFeature(f);
			}
		}
		return null;
	}
	getFrameSurroundings(holeId) {
		let source = this.frameElementSources.find((s) => s.get('holeId') == holeId);
		if (!source) {
			return null;
		}
		source.getFeatures();
		let writer = new GeoJSON();
		return writer.writeFeatures(source.getFeatures());
	}
	hasSurroundings() {
		return this.sharedElementsSource.getFeatures().length > 0;
	}
	getAnalyticsForHole(holeId) {
		let hasOutline = this.getFrameFeature(holeId);
		let source = this.frameElementSources.find((s) => s.get('holeId') == holeId);
		if (!source) {
			return {
				hasGreen: false,
				hasTee: false,
				hasFocus: false,
				hasOutline: hasOutline ? true : false,
				green: {
					front: null,
					back: null,
					center: null
				},
				tee: {
					yellowblack: false,
					greenwhite: false,
					orange: false,
					red: false,
					blue: false,
					yellow: false,
					white: false,
					black: false
				}
			};
		}
		function hasFeature(features, elementName) {
			return features.find((f) => f.get('elementName') === elementName) ? true : false;
		}
		let features = source.getFeatures();
		let data = {
			hasOutline: true,
			hasGreen: hasFeature(features, 'green'),
			hasTee: hasFeature(features, 'tee'),
			hasFocus: hasFeature(features, 'focus'),
			green: {
				front: hasFeature(features, 'greenfront'),
				back: hasFeature(features, 'greenback'),
				center: hasFeature(features, 'greencenter')
			},
			tee: {
				yellowblack: hasFeature(features, 'teeyellowblack'),
				greenwhite: hasFeature(features, 'teegreenwhite'),
				orange: hasFeature(features, 'teeorange'),
				red: hasFeature(features, 'teered'),
				blue: hasFeature(features, 'teeblue'),
				yellow: hasFeature(features, 'teeyellow'),
				white: hasFeature(features, 'teewhite'),
				black: hasFeature(features, 'teeblack')
			}
		};
		return data;
	}
	getMiscDataForHole(holeId) {
		let source = this.frameElementSources.find((s) => s.get('holeId') == holeId);
		if (!source) {
			return null;
		}
		function getCoordinatesForFeature(features, elementName) {
			let feature = features.find((f) => f.get('elementName') === elementName);
			if (!feature) {
				return null;
			}
			let coords = toLonLat(feature.getGeometry().getCoordinates());
			return {
				name: 'WGS84',
				epsg: '4326',
				x: 0,
				y: 1,
				coordinates: coords
			};
		}
		let features = source.getFeatures();
		let data = {
			hasGreen: features.find((f) => f.get('elementName') === 'green') ? true : false,
			hasTee: features.find((f) => f.get('elementName') === 'tee') ? true : false,
			green: {
				front: getCoordinatesForFeature(features, 'greenfront'),
				back: getCoordinatesForFeature(features, 'greenback'),
				center: getCoordinatesForFeature(features, 'greencenter')
			},
			tee: {
				yellowblack: getCoordinatesForFeature(features, 'teeyellowblack'),
				greenwhite: getCoordinatesForFeature(features, 'teegreenwhite'),
				orange: getCoordinatesForFeature(features, 'teeorange'),
				red: getCoordinatesForFeature(features, 'teered'),
				blue: getCoordinatesForFeature(features, 'teeblue'),
				yellow: getCoordinatesForFeature(features, 'teeyellow'),
				white: getCoordinatesForFeature(features, 'teewhite'),
				black: getCoordinatesForFeature(features, 'teeblack')
			}
		};
		return data;
	}
	getSurroundings() {
		let writer = new GeoJSON();
		return writer.writeFeatures(this.sharedElementsSource.getFeatures());
	}
	loadJson(data, fitFrames, clearHistory) {
		this.isRealoadingData = true;
		let reader = new GeoJSON()
		if (fitFrames == undefined) {
			fitFrames = true;
		}
		if (clearHistory === undefined) {
			clearHistory = true;
		}
		if (data.frames) {
			for (let frame of data.frames) {
				let features_ = reader.readFeatures(frame.geometry);
				features_.forEach((f) => {
					f.unset('selected');
					f.unset('focused');
					f.unset('hidden');
					f.unset('unfinished');
					f.unset('overlapping')
					f.unset('intersecting')
					f.unset('modifyGeometry')
					if (f.get('objectType') == 'frame') {
						f.set('holeId', frame.holeId);
						f.set('displayName', frame.displayName);
					}
					if (!f.get('uid')) {
						f.set('uid', nanoid());
					}
				});
				this.framesSource.addFeatures(features_);
				if (frame.surroundings) {
					let features_ = reader.readFeatures(frame.surroundings);
					features_.forEach((f) => {
						f.unset('selected');
						f.unset('focused');
						f.unset('hidden');
						f.unset('unfinished');
						f.unset('overlapping')
						f.unset('intersecting')
						f.unset('modifyGeometry')
						f.set('holeId', frame.holeId);
					});
					let s = new VectorSource();
					s.set('name', 'FrameElements');
					s.set('holeId', frame.holeId);
					s.addFeatures(features_);
					this.frameElementSources.push(s);
				}
			}
		}
		if (data.surroundings && data.surroundings != '') {
			let features_ = reader.readFeatures(data.surroundings);
			features_.forEach((f) => {
				f.unset('selected');
				f.unset('focused');
				f.unset('hidden');
				f.unset('unfinished');
				f.unset('overlapping')
				f.unset('intersecting')
				f.unset('modifyGeometry')
				let element = getElement(f.get('elementName'));
				let placeover = getElement(f.get('placeabove'));
				let index = element.zIndex
				if (placeover) {
					index = placeover.zIndex + 1
				}
				f.set('order', index);
				if (!f.get('uid')) {
					f.set('uid', nanoid());
				}
			});
			this.sharedElementsSource.addFeatures(features_);
		}
		if (fitFrames) {
			if (this.framesSource.getFeatures().length > 0) {
				this.fitSource(this.framesSource);
			} else {
				this.fitSource(this.sharedElementsSource);
			}
		}
		if (clearHistory) {
			this.undoRedo.clear();
		}
		this.isRealoadingData = false;
		this.updateFeatureHighlights();
	}
	simplifyAllPolygons(maxPoints = 100) {
		let features = this.sharedElementsSource.getFeatures().filter((f) => f.getGeometry().getType() == 'Polygon');
		for (let f of features) {
			let l1 = f.getGeometry().getCoordinates()[0].length
			if (l1 > maxPoints) {
				GeometryUtils.simplifyFeature(f);
				let l2 = f.getGeometry().getCoordinates()[0].length
				let sum = l1 - l2
				if (sum) {
					console.log(`simplified polygon by removing ${sum} coordinates`)
				}
			}
		}
		for (let s of this.frameElementSources) {
			let features = s.getFeatures().filter((f) => f.getGeometry().getType() == 'Polygon');
			for (let f of features) {
				let l1 = f.getGeometry().getCoordinates()[0].length
				if (l1 > maxPoints) {
					GeometryUtils.simplifyFeature(f);
					let sum = l1 - l2
					if (sum) {
						console.log(`simplified polygon by removing ${sum} coordinates`)
					}
				}
			}
		}
	}
	toggleFocus() {
		if (this.focusedFrameFeature) {
			this.unfocus();
		} else if (this.selectedFeature) {
			this.focus();
		}
	}
	getFrameFeature(holeId) {
		for (let f of this.framesSource.getFeatures()) {
			if (f.get('holeId') == holeId) {
				return f;
			}
		}
		return null;
	}
	updateIntersectingFeatures(feature) {
		let features = this.sharedElementsSource.getFeatures().filter((f) => f.getGeometry().getType() == 'Polygon');
		for (let f of features) {
			f.unset('intersecting');
		}
		if (!feature) {
			return
		}
		let intersecting = GeometryUtils.getIntersectingPolygons(feature, features, {
			checkOrder: true,
			ignoreFocus: true,
			matchElementName: true
		});
		for (let f of intersecting) {
			f.set('intersecting', true);
		}
	}
	enableShowIntersectingElements() {
		this.showIntersectingElements = true;
		this.updateIntersectingFeatures(this.selectedFeature)
	}
	disableShowIntersectingElements() {
		this.showIntersectingElements = false;
		let features = this.sharedElementsSource.getFeatures().filter((f) => f.getGeometry().getType() == 'Polygon');
		for (let f of features) {
			f.unset('intersecting');
		}
	}
	enableShowOverlappingElements() {
		this.showOverlappingElements = true;
		this.updateFeatureHighlights()
	}
	disableShowOverlappingElements() {
		this.showOverlappingElements = false;
		let features = this.sharedElementsSource.getFeatures().filter((f) => f.getGeometry().getType() == 'Polygon');
		for (let f of features) {
			f.unset('overlapping');
		}
	}
	updateOverlappingElements() {
		let features = this.sharedElementsSource.getFeatures().filter((f) => f.getGeometry().getType() == 'Polygon');
		for (let f of features) {
			f.unset('overlapping');
		}
		for (let f of features) {

			let extent = f.getGeometry().getExtent();
			let intersecting = this.sharedElementsSource.getFeaturesInExtent(extent).filter((f) => f.getGeometry().getType() == 'Polygon');

			for (let i of intersecting) {
				if (f.get('order') >= i.get('order')) {
					if (GeometryUtils.isContainedInPolygon(f, i)) {
						i.set('overlapping', true);
					}
				}
			}
		}
	}
	updateFeatureHighlights() {
		if (this.isRealoadingData) {
			return
		}
		if (this.showIntersectingElements === true) {
			this.updateIntersectingFeatures(this.selectedFeature)
		}
		if (this.showOverlappingElements === true) {
			this.updateOverlappingElements()
		}
	}
	panTo(coordinates, zoom, animated) {
		if (!zoom) {
			zoom = 14;
		}
		if (animated == undefined) {
			animated = true;
		}
		let converted = fromLonLat([coordinates[0], coordinates[1]]);
		if (animated) {
			this.view.animate({
				center: converted,
				duration: 500,
				zoom: zoom,
				rotation: 0,
				padding: this.viewPadding
			});
		} else {
			this.view.setCenter(converted);
			this.view.setZoom(zoom);
		}
	}
	selectFrame(holeId, panTo, animated, rotate, focus) {
		if (panTo === undefined) {
			panTo = true;
		}
		if (focus == undefined) {
			focus = true;
		}
		let frame = this.getFrameFeature(holeId);
		if (!frame) {
			return;
		}
		this.selectFeature(frame);

		if (focus) {
			this.focus(frame);
		} else if (panTo) {
			this.fitFeature(frame, animated, rotate);
		}
	}
	focus(feature) {
		this.unfocus();
		if (!feature) {
			feature = this.selectedFeature;
		}
		if (!feature) {
			return;
		}
		if (feature.get('objectType') != 'frame') {
			return;
		}
		this.deactivateTool()
		feature.set('focused', true);
		let holeId = feature.get('holeId');
		let source = this.frameElementSources.find((s) => s.get('holeId') == holeId);
		if (!source) {
			source = new VectorSource();
			source.set('holeId', holeId);
			this.frameElementSources.push(source);
		}
		this.focusedFrameFeature = feature;
		this.framesLayer.focusedHoleId = holeId;
		if (this.focusedFrameLayer) {
			this.map.removeLayer(this.focusedFrameLayer);
			this.focusedFrameLayer = null;
		}
		this.removeSourceListerners(this.focusedFrameSource);
		this.addSourceListerners(source);
		this.focusedFrameSource = source;
		this.focusedFrameLayer = new ElementsLayer(this.map, source, false);
		this.focusedFrameLayer.setZIndex(5);
		this.deselectFeature(feature);
		this.fitFeature(feature, false, true);
		// let geometry = feature.getGeometry()
		// let coordinates = geometry.getCoordinates()[0]
		// let angle = Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]) + Utils.degreesToRadians(-90)
		// this.view.setRotation(angle)
		// this.view.fit(geometry);

		this.map.addLayer(this.focusedFrameLayer);
		this.trigger('frameFocused', { feature: feature, source: source });
	}
	unfocus() {
		let f = this.focusedFrameFeature;
		if (f) {
			f.unset('focused');
		} else {
			return;
		}
		this.deselectFeature(f);
		this.framesLayer.focusedHoleId = null;
		this.focusedFrameSource = null;
		this.focusedFrameFeature = null;
		this.trigger('frameSelected', null);
		this.trigger('frameFocused', null);
		if (this.focusedFrameLayer) {
			this.map.removeLayer(this.focusedFrameLayer);
			this.focusedFrameLayer = null;
		}
		// if(!this.selectedFeature || this.selectedFeature.get('holeId') == f.get('holeId')) {
		//     f.set('selected', true)
		//     this.selectedFeature = f
		// }
	}
	removeFeatureListerners(feature) {
		if (!feature) {
			return;
		}
		let f = this.featureUpdated.bind(this);
		//feature.un('change', f)
		feature.un('propertychange', f);
	}
	addFeatureListerners(feature) {
		if (!feature) {
			return;
		}
		let f = this.featureUpdated.bind(this);
		//feature.on('change', f)
		feature.on('propertychange', f);
	}
	removeSourceListerners(source) {
		if (!source) {
			return;
		}
		let f = this.sourceUpdated.bind(this);
		source.un('addfeature', f);
		source.un('removefeature', f);
	}
	addSourceListerners(source) {
		if (!source) {
			return;
		}
		let f = this.sourceUpdated.bind(this);
		source.on('addfeature', f);
		source.on('removefeature', f);
	}
	trigger(event, val1, val2, val3) {
		if (!this.listeners[event]) {
			return;
		}
		for (let l of this.listeners[event]) {
			l(val1, val2, val3);
		}
	}
	featureUpdated(evt) {
		if (evt.type == 'propertychange') {
			if (evt.key == 'selected' || evt.key == 'focused' || evt.key == 'hidden' || evt.key == 'clearCache' || evt.key === 'intersecting' || evt.key === 'overlapping' || evt.key === 'modifyGeometry') {
				return;
			}
		}
		if (evt.target.get('objectType') === 'frame' || evt.target.get('elementName')) {
			this.trigger('featureUpdated', evt.target, evt);
		}
	}
	getFramesInExtent(extent) {
		return this.framesSource.getFeaturesInExtent(extent);
	}
	getFeaturesInExtent(extent) {
		let features = [];
		for (let source of this.frameElementSources) {
			let f = source.getFeaturesInExtent(extent);
			features = features.concat(f);
		}
		let f = this.sharedElementsSource.getFeaturesInExtent(extent);
		features = features.concat(f);
		return features;
	}
	sourceUpdated(evt) {

		if (evt.type == 'addfeature') {

			this.trigger('featureAdded', evt.feature, evt);
			if (evt.feature) {
				let _this = this;
				let name = evt.feature.get('elementName');
				let el = getElement(name);
				if (el && el.onePerFrame === true) {
					setTimeout(() => {
						_this.deactivateTool();
					}, 20);
				}
				this.updateFeatureHighlights()
				// else if(evt.feature.get('objectType') == "frame") {
				//     evt.feature.set('selected', true)
				//     this.selectFeature(evt.feature)
				// }
			}
		} else if (evt.type == 'removefeature') {
			this.trigger('featureRemoved', evt.feature, evt);
			this.updateFeatureHighlights()
		}
		this.trigger('sourceUpdated', evt.target, evt.feature, evt);
	}
	#beginMeasurement() {
		if (this.measureTool) {
			this.map.removeInteraction(this.measureTool);
		}
		if (this.measurementVector) {
			this.map.removeLayer(this.measurementVector);
		}
		if (!this.measurementSource) {
			this.measurementSource = new VectorSource();
		}
		this.measurementVector = new VectorLayer({
			source: this.measurementSource,
			style: {
				'fill-color': 'rgba(255, 255, 255, 0.2)',
				'stroke-color': '#ffcc33',
				'stroke-width': 2,
				'circle-radius': 7,
				'circle-fill-color': '#ffcc33'
			}
		});
		const _this = this;
		this.map.addLayer(this.measurementVector);
		this.measureTool = new MeasureTool(this.measurementSource, 'LineString', function (event, output, position) {
			if (event === 'end') {
				//_this.endMeasurement()
			} else {
				_this.trigger('measurementUpdated', {
					type: 'LineString',
					output: output,
					endPosition: position
				});
			}
		});
		this.map.addInteraction(this.measureTool);
	}
	#endMeasurement() {
		if (this.measureTool) {
			this.map.removeInteraction(this.measureTool);
		}
		if (this.measurementVector) {
			this.map.removeLayer(this.measurementVector);
		}
		this.measureTool = null;
		this.measurementVector = null;
		this.measurementSource = null;
	}
	editSelectedFeature() {
		if (!this.selectedFeature) {
			return;
		}
		let name = this.selectedFeature.get('elementName');
		this.activateTool(name, {
			holeId: this.selectedFeature.get('holeId'),
		}, true)
	}
	activateTool(name, props, modifySelected) {
		if (!props) {
			props = {}
		}
		let _this = this
		let holeId = props ? props.holeId : null;
		let freehand = props ? props.freehand : false;
		if (this.currentTool) {
			this.deactivateTool()
		}
		let modifyFeature = null
		if (modifySelected && this.selectedFeature && this.selectedFeature.getGeometry().getType() == 'Polygon' && this.selectedFeature.get('objectType') !== 'frame') {
			modifyFeature = this.selectedFeature
		}
		this.deselectFeature();
		if (name == this.currentTool) {
			return;
		}
		if (freehand === undefined) {
			freehand = false;
		}
		if (name == 'addFrame') {
			if (!this.canAddFrame(holeId)) {
				console.warn('Cannot add duplicate frame for HoleId');
				return;
			}
		}
		this.#setCurrentTool(name);
		if (name == 'addFrame') {
			this.#beginAddFrame(holeId);
			return;
		}
		if (name == 'measure') {
			this.#beginMeasurement();
			return;
		}
		if (name == 'union') {
			this.unionTool = new UnionTool(function (n, r) {
				_this.sharedElementsSource.addFeature(n)
				_this.sharedElementsSource.removeFeature(r[0])
				_this.sharedElementsSource.removeFeature(r[1])
			})
			return;

		}
		let element = getElement(name);
		let source = this.sharedElementsSource;
		if (element && element.frameOnly && this.focusedFrameFeature) {
			holeId = this.focusedFrameFeature.get('holeId');
			source = this.frameElementSources.find((s) => s.get('holeId') === holeId);
			if (!source) {
				window.dispatchEvent(new CustomEvent('error', { detail: { message: `Unable to add ${element.title} . Please try again.` } }))
				return
			}
		} else {
			holeId = null;
		}
		props.holeId = holeId;
		this.drawTool = new DrawElement(name, source, props);

		this.map.addInteraction(this.drawTool);
		if (modifyFeature) {
			let coordinates = modifyFeature.getGeometry().getCoordinates()[0]
			coordinates.pop()
			this.drawTool._modifyFeature = modifyFeature
			this.drawTool._modifyFeatureSource = this.getFeatureSource(modifyFeature)
			this.deleteFeature(modifyFeature)
			this.drawTool.appendCoordinates(coordinates)
		}
	}
	deactivateTool() {
		this.#setCurrentTool(null);
		if (this.measureTool) {
			this.#endMeasurement();
		} else if (this.drawTool) {
			this.map.removeInteraction(this.drawTool);
			this.drawTool = null;
		} else {
			this.#endAddFrame();
		}
		this.trigger('drawEnd');
	}
	getHoleSource(holeId) {
		return this.frameElementSources.find((s) => s.get('holeId') == holeId);
	}
	getFeatureSource(feature) {
		let holeId = feature.get('holeId');
		if (holeId) {
			if (this.selectedFeature.get('objectType') == 'frame') {
				return this.framesSource;
			} else {
				return this.frameElementSources.find((s) => s.get('holeId') == holeId);
			}
		} else {
			return this.sharedElementsSource;
		}
	}
	currentSource() {
		if (this.focusedFrameSource) {
			return this.focusedFrameSource;
		}
		return this.sharedElementsSource;
	}
	sourceHasElement(source, element) {
		if (!source) {
			source = this.currentSource();
		}
		let features = source.getFeatures();
		for (let f of features) {
			if (f.get('elementName') === element.name) {
				return true;
			}
		}
		return false;
	}
	canDraw(name, holeId) {
		if (name == 'addFrame') {
			if (!holeId || !this.canAddFrame(holeId)) {
				return false;
			}
			return true;
		}
		let element = getElement(name);
		if (!this.focusedFrameSource) {
			return element.frameOnly === false;
		}
		if (!element.onePerFrame) {
			return true;
		}
		let source = this.currentSource();
		let features = source.getFeatures();
		for (let f of features) {
			if (f.get('elementName') === element.name) {
				return false;
			}
		}
		return true;
	}
	canAddFrame(holeId) {
		if (!holeId) {
			return true;
		}
		let arr = this.framesSource.getFeatures();
		for (let a of arr) {
			let h = a.get('holeId');
			if (h === holeId) {
				return false;
			}
		}
		return true;
	}
	#setCurrentTool(name) {
		this.currentTool = name;
		let element = getElement(name);
		if (name) {
			document.getElementById(this.target).style.cursor = `url(${getIconUrl(name)}) 9 24, crosshair`;
		} else {
			document.getElementById(this.target).style.cursor = 'default';
		}
		this.trigger('currentToolChange', name, element);
	}
	#beginAddFrame(holeId) {
		let props = deconstructHoleId(holeId);
		this.map.addLayer(this.addFramesLayer);
		this.addFrameTool.attach(this.map, this.addFramesSource, {
			holeId: holeId,
			displayName: props.groupShortName + props.holeNumber
		});
	}
	#endAddFrame() {
		let features = this.addFramesSource.getFeatures();
		if (features.length > 0) {
			this.framesSource.addFeature(features[0]);
			this.addFramesSource.removeFeature(features[0]);
		}
		this.map.removeLayer(this.addFramesLayer);
		this.addFrameTool.detach();
	}
}
